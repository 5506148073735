.btn-resume {
  margin-top: 10px;
  color: #e0aa17;
  background-color: transparent;
  padding: 8px 16px;
  border: #e0aa17 1px solid;
  border-radius: 5%;
}

a:hover {
  text-decoration: none;
  color: black !important;
}

.download-icon {
  color: inherit;
}

.btn-resume:hover {
  background-color: #ec971f;
}

.dropdown-menu {
  min-width: 200px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f0ad4e;
  color: #000;
}
