#contact {
    color: white;
}

.title {
    color: #e0aa17;
    padding-bottom: 15px;
    border-bottom-style: solid;
    border-bottom-width: 3.1px;
    width: 100%;
}

.contact-button {
    margin-top: 10px;
    color: #e0aa17;
    background-color: transparent;
    padding: 8px 16px;
    border: #e0aa17 1px solid;
    border-radius: 5%;
}

.modal {
    background-color: transparent!important;
}

.modal-header, .modal-body, .modal-title, .modal-form  {
    background-color: white!important;
    color: #0a192f;
}


ul {
    list-style-type: none;
    padding-left: 0px !important;

}


.fa {
    font-size: 25px;
    padding: 0px 15px 0px 0px;
}

.input {
    margin-bottom: .75rem;
    width: 100%;
    border-bottom-width: 4px;
    --tw-border-opacity: 1;
    border-color: #e0aa17;
    background-color: white;
    --tw-bg-opacity: 0.4;
    padding: .25rem;
}