.projects-wrapper {
    margin-bottom: 50px;
  }
  
.title {
    color: #e0aa17;
    padding-bottom: 15px;
    border-bottom-style: solid;
    border-bottom-width: 3.1px;
    width: 100%;
}
.card {
    border: none;
    background: #112240 !important;
    padding: 10px 5px;
}

.card-title {
    color: white;
    font-size: 20px;
}
.card-text {
    color: #A0ABC9;
    font-size: 14px;
}

.card:hover {
    transform: scale(1.05); 
    z-index: 1;
  }