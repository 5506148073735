.sidenav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    right: 0;
    padding-right: 5%;
    bottom: 10%;
    background-color: transparent;
}

.nav-links {
    list-style: none;
}

.social-links {
    list-style: none;
    text-align: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
}

.social-links li {
    display: inline;
    padding: 0px 3px;
    margin: 0px;

}

.sidenav .nav-links li.active a {
    color: #e0aa17;
    font-weight: bold;
}
