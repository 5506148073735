/* Importing google font  */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');

.text-shpere {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;
    margin-bottom: -40px;
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    letter-spacing: 0.0625em;
    font-size: 1.3em;
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: #e0aa17;
    text-transform: uppercase;
}

.tagcloud--item:hover {
    color: #ff6347;
}