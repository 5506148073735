.timeline-container {
  background-color: #0a192f;
  color: #a0abc9;
  padding: 0px;
  position: relative;
}

.section-title {
  color: #e0aa17;
  font-size: 2rem;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e0aa17;
}

.timeline {
  position: relative;
  padding-left: 30px;
}

.timeline-line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #e0aa17;
}

.timeline-scroll-dot {
  position: absolute;
  left: -4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: top 0.3s ease;
}

.timeline-item {
  position: relative;
  margin-bottom: 50px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.timeline-item.active {
  opacity: 1;
}

.timeline-dot {
  position: absolute;
  left: -34px;
  top: 24px; /* Adjust this value to align with the title */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e0aa17;
}

.timeline-content {
  padding-left: 20px;
}

.timeline-date {
  font-size: 0.9rem;
  color: #e0aa17;
  margin-bottom: 5px;
}

.timeline-title {
  color: #ffffff;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.timeline-subtitle {
  color: #e0aa17;
  font-size: 1rem;
  margin-bottom: 10px;
}

.timeline-details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.timeline-item.active .timeline-details {
  max-height: 500px;
}

.timeline-description {
  margin-bottom: 10px;
}

.timeline-skills {
  font-size: 0.9rem;
  color: #8892b0;
}

.active-dot {
  color: white;
  background-color: white;
}
