.footer-wrapper {
    color: #A0ABC9;
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    font-size: 16px;
}

.author {
    color: #e0aa17;
}